.logo-confia {
	width: 100px;
	margin: 0px 20px 20px 10px;
	float: right;
}
#display-step-3 .checkout-card h5 {
	margin-bottom: 15px !important;
	padding-top: 7px;
}
#display-step-3 .checkout-card ol li {
	margin-bottom: 5px;
}
#display-step-3 .checkout-card ol li strong {
	font-weight: bold;
}
.checkout-message-content {
	padding-right: 20px;
}
.centered-text,
.main-thumbnail {
	text-align: center;
}
.start-process-btn {
	border: 0;
	white-space: nowrap;
}
.centered-text {
	margin-bottom: 20px !important;
	margin-top: 20px !important;
}
.centered-text img {
	width: 100px;
}
.main-thumbnail img {
	width: 200px;
}
#display-step-3 .checkout-message-content p {
	margin-top: 12px;
	margin-bottom: 8px;
}
#display-step-3 .checkout-message.error p {
	color: #9e0038;
}
#display-step-3 .checkout-message.error {
	background-color: #fce3ec;
}

.features-table .row-price {
	white-space: nowrap;
}
p strong {
	font-weight: bold;
}
.features-table .price-inner-row .row-price {
	color: #788398;
}
.seat-summary-card .features-table .price-summary-row.lined {
	border-top: 1px solid #e1e5ea;
}
.price-summary-row:not(.seat-color-price-holder) {
	font-weight: bold;
}
span.green-badge {
	background-color: #e0f2d1;
	padding: 3px 8px;
	border-radius: 5px;
	color: #026c02;
	float: right;
	margin-top: 0;
	font-size: 12px;
}
a.profile-circle {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #e1e5ea;
	border-radius: 50%;
	line-height: 40px;
	text-align: center;
	color: #788398;
	vertical-align: top;
}
.seat-details-toggle {
	cursor: pointer;
}
.price-inner-row.seat-color-price-holder.close {
	overflow: hidden;
	height: 0;
	transition: 0.3s;
}
.price-inner-row.seat-color-price-holder.open {
	overflow: hidden;
	height: 24px;
	transition: 0.3s;
}
.vat-notice {
	font-size: 12px;
	color: #788398;
	margin-top: -15px;
	display: block;
	margin-bottom: 20px;
}

@media screen and (max-width: 780px) {
	.start-process-btn {
		white-space: wrap;
		line-height: 0.9em;
	}
}
